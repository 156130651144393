<template>
    <Main :show-title="!isLoading && !!contracts.length">

        <CardList v-if="isLoading" :columns="4">
            <Placeholder v-for="placeholder in placeholders[0]" :key="placeholder" element="li" :classes="['card column-1']" :height="237"/>
            <Placeholder v-for="placeholder in placeholders[1]" :key="placeholder" element="li" :classes="['card column-2']" :height="237"/>
            <Placeholder v-for="placeholder in placeholders[2]" :key="placeholder" element="li" :classes="['card column-3']" :height="237"/>
            <Placeholder v-for="placeholder in placeholders[3]" :key="placeholder" element="li" :classes="['card column-4']" :height="237"/>
        </CardList>

        <template v-else-if="!contracts.length">
            <Empty v-if="!subscription.isActive" illustration="contracts">
                <template #title>Intet abonnement</template>
                <template #description>Tilknyt
                    <span @click="$router.push('/settings')" class="link">abonnement</span>
                    til konto
                </template>
            </Empty>
            <Empty v-else illustration="contracts">
                <template #title>Ingen kontrakter<br>tilføjet endnu</template>
                <template #description>Tilføj din
                    <router-link to="/contracts/create">første</router-link>
                    kontrakt
                </template>
            </Empty>
        </template>

        <CardList v-else :columns="columns">
            <ContractCard v-for="contract in contracts" :key="contract.id" v-bind="contract" />
        </CardList>

    </Main>

    <ActionButton icon="plus" :float="true" @click="$router.push('/contracts/create')" :disabled="!subscription.isActive" :tooltip="buttonTooltip"/>

    <ModelRouterView/>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue';
import Main from '@/components/Main.vue';
import useUnsubscribe from '@/utils/useUnsubscribe';
import { contractsCollection } from '@/utils/collections';
import ModelRouterView from '@/components/ModelRouterView.vue';
import CardList from '@/components/CardList.vue';
import ContractCard from '@/components/cards/ContractCard.vue';
import useState from '@/store';
import { watch } from 'vue';
import Placeholder from '@/components/Placeholder.vue';
import Empty from '@/components/Empty.vue';

export default {
    name: 'Contracts',
    components: {
        CardList,
        Main,
        ActionButton,
        ModelRouterView,
        ContractCard,
        Placeholder,
        Empty,
    },
    setup() {
        const { collections, subscription } = useState();
        const placeholders = collections.value?.contracts || [0, 0, 0, 0];
        const column = (contract) => contract?.status?.state || 'DRAFT';
        const sort = ['DRAFT', 'SENT', 'ACCEPTED', 'REJECTED'];

        const { docs, isLoading, columns } = useUnsubscribe(contractsCollection().orderBy('company'), placeholders, column, sort);

        watch(() => docs.value, () => {
            // updateCollections('contracts', length);
        });

        return { contracts: docs, isLoading, placeholders, columns, subscription };
    },
    computed: {
        buttonTooltip() {
            return this.subscription.isActive ? 'Opret kontrakt' : 'Tilknyt et abonnement til din konto, for at oprette kontrakter';
        },
    },
};
</script>
